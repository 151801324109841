import apiClient from '@/services/axios'

export const getInvoiceList = ({ business_id, params }) => {
  return apiClient({
    method: 'get',
    url: 'invoice/query/list',
    headers: {
      'Business-Id': business_id,
    },
    params,
  })
}

export const getInvoiceDetail = ({ business_id, id }) => {
  return apiClient({
    method: 'get',
    url: `invoice/query/${id}`,
    headers: {
      'Business-Id': business_id,
    },
  })
}

export const getInvoiceByOrderId = (params) => {
  return apiClient({
    method: 'get',
    url: '/invoice/query',
    params,
  })
}

export const getListTagihan = ({ business_id, channel_id, payment_id, params }) => {
  return apiClient({
    method: 'get',
    url: `channel/distributor/payment/query/history/detail/${channel_id}/${payment_id}`,
    headers: {
      'Business-Id': business_id,
    },
    params,
  })
}